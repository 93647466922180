@import '~normalize.css';
@import '~src/theme/_library.scss';

html {
	font-size: 14px;
}

body {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-family: font('body');
	overflow-x: hidden;
}

::selection {
	background-color: color('blue');
	color: color('white');
}

code {
	font-family: font('mono');
}

@font-face {
	font-family: SansSerifQuote;
	src: local('Times New Roman'), local('Helvetica'), local('Arial'),
		local('Times'), local('Ubuntu');
	unicode-range: U+22, U+27;
}

h1 {
	color: color('blue');
	font-size: 47.5px;
	font-weight: weight('bold');
	line-height: 0.96;
	letter-spacing: 1.86px;
	margin: 3rem 0;
	word-wrap: break-word;
	overflow-wrap: break-word;
	text-transform: uppercase;
	font-family: SansSerifQuote, font('title');

	@include screen('sm') {
		font-size: 62px;
		line-height: 68px;
		letter-spacing: 2.43px;
	}
}

h2 {
	color: color('blue');
	font-weight: weight('bold');
	margin: 1.5rem 0;
	word-wrap: break-word;
	overflow-wrap: break-word;
	text-transform: uppercase;
	font-size: 37.5px;
	letter-spacing: 1.47px;
	line-height: 42.5px;
	font-family: SansSerifQuote, font('title');

	@include screen('sm') {
		font-size: 53px;
		line-height: 58px;
		letter-spacing: 2.08px;
		margin: 2rem 0;
	}
}

h3 {
	color: color('blue');
	font-family: font('heading');
	font-size: 28px;
	font-weight: weight('medium');
	letter-spacing: 1.17px;
	line-height: 28px;
	margin: 1rem 0;
	word-wrap: break-word;
	overflow-wrap: break-word;
	text-transform: uppercase;

	@include screen('sm') {
		margin: 1.5rem 0;
	}
}

h4,
h5,
h6 {
	color: color('blue');
	font-family: font('heading');
	font-weight: weight('medium');
	margin: 1rem 0;
	word-wrap: break-word;
	overflow-wrap: break-word;
}

a {
	color: color('black');
	font-weight: weight('medium');
	text-decoration: none;

	&:hover {
		color: color('blue-light');
	}

	&:active {
		color: color('gray');
	}
}

b,
strong {
	font-weight: weight('medium');
}

p {
	line-height: 25px;

	& a {
		color: color('blue');
		font-weight: weight('normal');

		&:hover {
			color: color('blue-light');
		}

		&:active {
			color: color('gray');
		}
	}
}

hr {
	border: none;
	border-top: 1px solid color('gray');
}

*:focus {
	outline: 0;
}

p,
div,
section {
	word-wrap: break-word;
}

.fr-view {
	h3 {
		margin: 1rem 0;
	}

	hr {
		margin: 3rem 0;

		@include screen(sm) {
			margin: 4rem 0;
		}
	}
}
