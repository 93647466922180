@import '~src/theme/_library.scss';

.sidebar {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 900;
	display: flex;
	justify-content: center;
	align-items: center;
}

.sidebar-overlay {
	opacity: 1;
	transition: opacity 0.5s ease-in;
	background: rgba($color: #000000, $alpha: 0.75);
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	cursor: pointer;
}

.sidebar-header {
	position: relative;
	padding: 20px;
	text-align: center;
	border-bottom: solid 1px #a9a9a9;
	font-weight: bold;
	-webkit-font-smoothing: auto;
	-moz-font-smoothing: auto;

	.header-icon {
		color: color('blue-light');
		margin-right: 5px;
	}
}

.sub-header {
	padding: 20px;
	background: #fff;
	text-align: left;
	font-weight: bold;
	font-size: 1.3rem;
	-webkit-font-smoothing: auto;
	-moz-font-smoothing: auto;
}

.sidebar-content {
	width: 100%;

	@include media-breakpoint(md) {
		width: 525px;
	}

	@include media-breakpoint(lg) {
		width: 525px;
	}

	display: flex;
	flex-direction: column;
	max-width: 100%;
	background: color('white');
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.13);
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	z-index: 1;
}

.sidebar-close-btn {
	@include no-appearance-button();
	position: absolute;
	top: 27px;
	right: 27px;
	transform: translate(50%, -50%);
	color: color('blue-light');
	cursor: pointer;
	transition: 150ms;

	svg {
		width: 1.5rem !important;
		height: 1.5rem !important;
		padding: 0.35rem;
		vertical-align: top;
	}
}

.sidebar-child-content {
	padding: 2rem;
	overflow-y: scroll;
	flex-grow: 1;
}
