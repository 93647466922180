@import '~src/theme/_library.scss';

.link {
	color: color('white');
	text-decoration: underline;

	&:hover {
		color: color('white');
	}
}

.container-class {
	background-color: color('blue-light') !important;
	justify-content: center !important;
	align-items: flex-start !important;

	.content-class {
		line-height: 1.57;
		display: flex;
		font-size: 14px;
		flex-direction: column;
		text-align: center;

		@include screen('sm') {
			max-width: 70%;
			flex-direction: row;
			text-align: left;
		}

		img {
			margin: 2rem 0;
			display: none;

			@include screen('sm') {
				margin: 0 3rem 0 0;
				display: inline;
			}
		}
	}

	.register-button {
		@include no-appearance-button;
		text-transform: lowercase;
		font-weight: 500;
		text-decoration: underline;
	}

	.button-accept {
		background-color: color('white') !important;
		border-radius: 5px !important;
		font-weight: 500;
		font-size: 14px;
		line-height: 2;
		letter-spacing: 1.45px;
		text-transform: uppercase;
		color: color('blue-light') !important;
		height: 51px;
		width: 85vw;

		@include screen('sm') {
			width: 150px;
		}
	}
}
