@import '~src/theme/_library.scss';

.loader {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	color: color('blue');
	font-size: 42pt;
}

.prompt {
	text-align: center;
	color: color('gray-dark');

	margin-top: 2rem;
	margin-bottom: 2rem;
}

.button-area {
	margin-top: 2rem;
	margin-bottom: 4rem;
}

.grid-2-md {
	@include screen(sm) {
		display: flex;
	}

	&-cell {
		flex: 1;

		@include screen(sm) {
			&:nth-of-type(1) {
				margin-right: 1rem;
			}
			&:nth-of-type(2) {
				margin-left: 1rem;
			}
		}
	}
}
