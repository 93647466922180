@import '~src/theme/_library.scss';

// Document each style
.checkbox-field-wrap {
	.message-text {
		@include screen('sm') {
			margin-bottom: 12px;
		}
	}
	.checkbox-list {
		list-style: none;
		padding: 0;
		margin: 0;

		& > div {
			display: none;
		}

		li {
			display: flex;
			justify-content: normal;

			& > div.checkbox-list-item {
				display: block !important;
				margin-right: 6px;
				margin-bottom: 22px;

				.checkbox-help-wrap {
					position: relative;

					svg {
						color: grey;
					}
					.checkbox-help {
						pointer-events: none;
						transition: opacity 0.5s;
						opacity: 0;
						position: absolute;
						top: -60px;
						width: max-content;
						padding: 20px;
						border: solid 1px #00a2db;
						border-radius: 5px;
						text-align: center;
						color: #00a2db;
						background: #fff;
					}
				}

				.checkbox-help-wrap:hover {
					cursor: pointer;
					.checkbox-help {
						display: inline;
						opacity: 1;
					}
				}
			}
		}
	}
}
