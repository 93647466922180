@import '~src/theme/_library.scss';

.footer {
	color: color('white');
	background-color: color('blue');
	text-align: center;

	&-wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-direction: column;
		margin: 1.5rem 0;

		@include screen('sm') {
			flex-direction: row;
			margin: 1rem 0 0 0;
		}
	}

	&-links {
		list-style: none;
		padding: 0;
		margin: 0.5rem 0;
		text-transform: uppercase;
		font-weight: weight('medium');

		display: flex;
		flex-direction: column;

		@include screen('sm') {
			flex-direction: row;
			margin: 0;
		}

		li {
			margin: 1rem;

			@include screen('sm') {
				margin: 1rem 1.5rem;
			}
		}

		a,
		a:hover {
			color: color('white');
		}
	}

	&-social-links {
		list-style: none;
		padding: 0;
		margin: 0 0 37px 0;
		text-transform: uppercase;
		font-weight: weight('medium');

		display: flex;
		justify-content: center;
		flex-direction: row;

		li {
			margin: 12px;
		}

		a,
		a:hover {
			color: color('white');
		}
	}

	&-text {
		padding: 0.5rem 0;
		font-size: 10px;
		background-color: color('blue-light');
		width: 100%;
		text-align: center;
	}
}

$lego-logo-width: 50px;
$tongal-logo-width: 108px;

@mixin logo-wrapper {
	display: inline-block;
	margin: 1.5rem 0;
	text-align: center;
	// This is to make the links centered. Remove if we get a lot more links.
	width: max($lego-logo-width, $tongal-logo-width);

	img {
		vertical-align: top;
	}
}

.tongal-logo-wrapper {
	@include logo-wrapper;

	@include screen('sm') {
		text-align: left;
		margin-right: 1rem;
	}

	img {
		width: $tongal-logo-width;
	}
}

.lego-logo-wrapper {
	@include logo-wrapper;

	@include screen('sm') {
		text-align: right;
		margin-left: 1rem;
	}

	img {
		width: $lego-logo-width;
	}

	@include screen('sm') {
		order: 1;
	}
}
