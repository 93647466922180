@import '~src/theme/_library.scss';

@mixin base-button() {
	appearance: none;
	background-color: color('blue');
	border-color: color('blue');
	border-radius: 5px;
	border-style: solid;
	border-width: 1px;
	color: color('white');
	cursor: pointer;
	font-weight: weight('medium');
	letter-spacing: 1.45px;
	outline: none;
	text-transform: uppercase;
	transition: 150ms;
	padding: 1rem 7.5px;

	@include media-breakpoint(xs) {
		padding: 1.25rem 15px;
	}

	@include media-breakpoint(sm) {
		padding: 1.25rem 30px;
	}

	&:hover,
	&:active {
		background-color: color('blue-light');
		border-color: color('blue-light');
	}

	&[disabled] {
		background-color: color('gray');
		border-color: color('gray');
		pointer-events: none;
		color: white;

		svg {
			color: white;
		}
	}
}

.normal {
	@include base-button();
	@include screen('sm') {
		white-space: nowrap;
	}

	&:hover,
	&:active {
		color: color('white');
	}
}

.white {
	@include base-button();
	background-color: color('white');
	border-color: color('blue-light');
	color: color('blue-light');

	&:hover,
	&:active {
		color: color('white');
	}
}

.muted {
	@include base-button();
	background-color: color('white');
	border-color: color('gray-dark');
	color: color('black');

	&:hover,
	&:active {
		background-color: color('white');
		border-color: color('gray');
	}
}

.nav {
	@include base-button();
	font-weight: weight('medium');
	letter-spacing: 1.45px;
	line-height: 2em;
	background-color: color('transparent');
	border-width: 0;
	color: color('black');
	padding: 0;

	&:hover {
		background-color: color('transparent');
		color: color('blue-light');
	}

	&:active {
		background-color: color('transparent');
		color: color('gray');
	}

	&:disabled {
		background-color: color('transparent');
		color: color('gray');
	}
}

.link {
	@include no-appearance-button();
	font-weight: weight('medium');
	cursor: pointer;
	margin: 0.75rem 0;
	transition: 150ms;
	color: color('blue-light');
	text-transform: none;
	&:hover {
		color: color('blue');
	}
}

.text {
	appearance: none;
	background: transparent;
	border: none;
	font-weight: weight('medium');
	margin: 0;
	outline: none;
	padding: 0;
	text-decoration: none;
	color: color('black');

	p & {
		color: color('blue');
		font-weight: weight('normal');
	}

	&:disabled {
		color: color('gray');
	}

	&:enabled {
		cursor: pointer;

		&:hover {
			color: color('blue-light');
		}

		&:active {
			color: color('gray');
		}
	}
}
