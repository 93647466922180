@import '~src/theme/_library.scss';

@mixin PageHeaderBackground {
	background-color: color('blue');
	background-image: url('~src/assets/images/bricks/header.svg');
	background-size: 344px;

	// Fallback if `calc` is not supported
	background-position: center top;
	background-position: left calc(50vw - 72px) top;
}
