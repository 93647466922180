@use "sass:math";
@import '~src/theme/_library.scss';

// The prefix that will be applied to the select via `classNamePrefix`
$classNamePrefix: react-select;

// The padding that should be between the text of the input/labels and the
// border. The tags will have a border that will make the elements closer to the
// edge, but the text should remain this distance from the edge.
$input-padding-y: 1.5rem;
$input-padding-x: $input-padding-y;

// The amount of padding the selected tags should have
$tag-padding-x: 0.5rem;
$tag-padding-y: $tag-padding-x;

// The amount of spacing between the selected tags
$tag-spacing-x: 0.5rem;
$tag-spacing-y: $tag-spacing-x;

// Overall border radius of the input
$border-radius: 5px;

// The main container to be placed on the select
.container {
	:global {
		.#{$classNamePrefix} {
			&__control {
				box-shadow: none;
				transition: 150ms;
				border-radius: $border-radius;

				&,
				&:hover {
					border-color: color('blue');
				}

				&--is-focused {
					&,
					&:hover,
					& + .#{$classNamePrefix}__menu {
						border-color: color('blue');
					}
				}
			}

			&__menu {
				// Enough to cover up the border radius to appear as part of the input
				margin-top: -$border-radius;
				background: color('white');
				box-shadow: none;
				border-radius: 0 0 $border-radius $border-radius;
				border-width: 1px;
				border-style: solid;
				border-color: color('blue');
				border-top: 0;
				overflow: hidden;
				transition: 150ms;
			}

			&__menu-list {
				padding: 0;
			}

			&__value-container {
				padding: $input-padding-y - $tag-padding-y - math.div($tag-spacing-y, 2)
					$input-padding-x - $tag-padding-x - math.div($tag-spacing-x, 2);

				input {
					padding: 0;
					margin: $tag-padding-y + math.div($tag-spacing-y, 2) $tag-padding-x +
						math.div($tag-spacing-x, 2);
				}
			}

			&__Input {
				align-self: start;
				> div {
					padding: 0;
					margin: 0;
				}
			}

			&__multi-value + div input {
				margin-left: math.div($tag-spacing-x, 2);
			}

			&__placeholder {
				margin: 0 $tag-padding-x + math.div($tag-spacing-x, 2);
			}

			&__single-value {
				margin-right: -100%;
				max-width: unset;
				padding: $tag-padding-y + math.div($tag-spacing-y, 2) $tag-padding-x +
					math.div($tag-spacing-x, 2);
				position: relative;
				top: 0;
				transform: none;
				white-space: normal;
				width: 100%;

				// Basic line height
				min-height: 1.15em;
			}

			&__indicators {
				padding: 0;

				svg {
					color: color('gray');
				}
			}

			&__indicator {
				width: 1.5rem;
				align-self: stretch;
				box-sizing: content-box;
				padding: 1rem;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			&__dropdown-indicator {
				cursor: pointer;

				svg {
					color: color('blue');
				}
			}

			&__indicator-separator {
				height: 2rem;
				align-self: unset;

				// Don't show a separator between icons & the input
				&:first-child {
					display: none;
				}
			}

			&__option {
				background-color: color('white');

				&--is-focused {
					background-color: color('gray-light');
				}

				&--is-selected {
					background-color: color('blue');
				}
			}

			&__input {
				margin-right: -$tag-padding-x - math.div($tag-spacing-x, 2);
			}

			&__multi-value {
				background-color: color('gray-light');
				margin: math.div($tag-spacing-y, 2) math.div($tag-spacing-x, 2);
				border-radius: $border-radius;
				align-self: flex-start;

				&__label {
					padding: $tag-padding-y $tag-padding-x;
					padding-right: 0;
					font-size: inherit;
				}

				&__remove {
					color: color('red');
					padding: $tag-padding-y $tag-padding-x;
					cursor: pointer;

					&:hover {
						background-color: transparent;
					}
				}
			}
		}
	}

	&-invalid {
		:global {
			.#{$classNamePrefix} {
				&__menu,
				&__control {
					&,
					&:hover {
						border-color: color('red') !important;
					}
				}
			}
		}
	}

	&-fixed {
		:global {
			.#{$classNamePrefix} {
				&__menu,
				&__control {
					&,
					&:hover {
						border-color: color('green') !important;
					}
				}
			}
		}
	}

	&-open {
		:global {
			.#{$classNamePrefix} {
				&__menu {
					position: relative;
				}

				&__menu-list {
					max-height: none;
				}

				&__option {
					padding: 1.5rem;
				}
			}
		}
	}
}
