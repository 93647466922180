@import '~src/theme/_library.scss';

.avatar-image {
	box-sizing: border-box;
	line-height: 0;
	overflow: hidden;
	position: relative;
	margin: 0 auto;

	&.small {
		width: 42px;
	}

	&.medium {
		width: 75px;
	}

	&.large {
		width: 100%;
		max-width: 250px;
	}

	&.full {
		width: 100%;
	}

	// Keep 1:1 aspect ratio
	&:after {
		content: '';
		display: block;
		padding-bottom: 100%;
	}

	img,
	svg {
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 5px;
		position: absolute;
		left: 0;
	}

	.loading {
		height: 20%;
		top: 40%;
		width: 100%;
		position: absolute;

		> div.loading-inner-content {
			display: flex;
			justify-content: center;
			color: #e1e1e1;

			span {
				display: inline-block;
				line-height: normal;
				font-size: 30px;
				width: max-content;

				svg {
					position: relative;
				}
			}
		}
	}
}
.update-in-progress {
	-webkit-filter: grayscale(100%);
	filter: grayscale(100%);
}
