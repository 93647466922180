@use "sass:math";
@import '~src/theme/_library.scss';

.content {
	position: relative;
	width: 100%;
}

@mixin background-base {
	pointer-events: none;
	position: absolute;
	top: 0;
	bottom: 0;
	user-select: none;
	width: 200px;
	background-size: 100%;
}

@mixin background-left {
	@include background-base;
	right: 100%;
	margin-right: 0.25rem;

	@include screen(sm) {
		margin-right: 0.75rem;
	}

	@include screen(md) {
		margin-right: 1.5rem;
	}

	@include screen(lg) {
		margin-right: 2rem;
	}
}

@mixin background-right {
	@include background-base;
	left: 100%;
	margin-left: 0.25rem;

	@include screen(sm) {
		margin-left: 0.75rem;
	}

	@include screen(md) {
		margin-left: 1.5rem;
	}

	@include screen(lg) {
		margin-left: 2rem;
	}
}

.hero {
	margin: 0 auto;
	max-width: 100%;
	width: 600px;
	position: relative;
	// Apply display that expands to include inner margins
	display: flex;

	&-wrapper {
		width: 100%;
	}

	&-left-background {
		$mobile-bg: '~src/assets/images/bricks/body-wide-mobile-left.svg';
		$mobile-bg-width: 148px;
		$mobile-bg-height: 564px;

		@include background-left;
		background-image: url($mobile-bg);
		background-repeat: no-repeat;
		min-height: $mobile-bg-height;
		width: math.div($mobile-bg-width, 2);

		@include screen(sm) {
			width: $mobile-bg-width * math.div(3, 4);
		}

		@include screen(md) {
			width: $mobile-bg-width;
		}

		@include screen('lg') {
			background-image: url('~src/assets/images/bricks/body-wide-desktop-left.svg');
			min-height: 601px;
			width: 260px;
		}
	}

	&-right-background {
		$mobile-bg: '~src/assets/images/bricks/body-wide-mobile-right.svg';
		$mobile-bg-width: 148px;
		$mobile-bg-height: 564px;

		@include background-right;
		background-image: url($mobile-bg);
		background-repeat: no-repeat;
		min-height: $mobile-bg-height;
		width: math.div($mobile-bg-width, 2);

		@include screen(sm) {
			width: $mobile-bg-width * math.div(3, 4);
		}

		@include screen(md) {
			width: $mobile-bg-width;
		}

		@include screen(lg) {
			background-image: url('~src/assets/images/bricks/body-wide-desktop-right.svg');
			min-height: 601px;
			width: 260px;
		}
	}
}

@mixin pseudo-content {
	content: ' ';
	display: block;
	position: absolute;
}

.hero2 {
	width: 610px;
	max-width: 100%;
	margin: auto;
	position: relative;

	&-with-background {
		padding-top: 150px;
		background: top center/300px no-repeat
			url('~src/assets/images/homepage/homepage-mobile.svg');

		&-bottom {
			padding-top: 0;
			padding-bottom: 150px;
			background-position: bottom center;
		}

		@include screen(sm) {
			padding: 0;
			background: none;

			&:before {
				@include pseudo-content;
				margin-right: 15px;
				right: 100%;
				top: 50%;
				transform: translateY(-50%);
				width: 261px;
				height: 339px;
				background-image: url('~src/assets/images/homepage/homepage-desktop-right.svg');

				@include screen(md) {
					margin-right: 30px;
				}

				@include screen(md) {
					margin-right: 45px;
				}

				@include screen(md) {
					margin-right: 60px;
				}
			}

			&:after {
				@include pseudo-content;
				margin-left: 15px;
				left: 100%;
				top: 50%;
				transform: translateY(-50%);
				width: 261px;
				height: 339px;
				background-image: url('~src/assets/images/homepage/homepage-desktop-left.svg');

				@include screen(md) {
					margin-left: 30px;
				}

				@include screen(md) {
					margin-left: 45px;
				}

				@include screen(md) {
					margin-left: 60px;
				}
			}
		}
	}
}

.narrow {
	margin: 0 auto;
	max-width: 100%;
	width: 800px;
	position: relative;
	// Apply display that expands to include inner margins
	display: flex;

	&-wrapper {
		width: 100%;
	}

	&-left-background {
		$mobile-bg: '~src/assets/images/bricks/body-narrow-mobile-left.svg';
		$mobile-bg-width: 121px;

		@include background-left;
		background-image: url($mobile-bg);
		width: math.div($mobile-bg-width, 2);
		background-repeat: repeat-y;

		@include screen(sm) {
			width: $mobile-bg-width * math.div(3, 4);
		}

		@include screen(md) {
			width: $mobile-bg-width;
			margin-right: 2.5rem;
		}

		@include screen(lg) {
			background-image: url('~src/assets/images/bricks/body-narrow-desktop-left.svg');
			width: 203px;
			margin-right: 4rem;
		}
	}

	&-right-background {
		$mobile-bg: '~src/assets/images/bricks/body-narrow-mobile-right.svg';
		$mobile-bg-width: 121px;

		@include background-right;
		background-image: url($mobile-bg);
		width: math.div($mobile-bg-width, 2);
		background-repeat: repeat-y;

		@include screen(sm) {
			width: $mobile-bg-width * math.div(3, 4);
		}

		@include screen(md) {
			width: $mobile-bg-width;
			margin-left: 2.5rem;
		}

		@include screen(lg) {
			background-image: url('~src/assets/images/bricks/body-narrow-desktop-right.svg');
			background-position-y: 20px;
			width: 183px;
			margin-left: 4rem;
		}
	}
}

.sidebar {
	display: flex;
	flex-direction: column;
	position: relative;

	@include screen('sm') {
		flex-direction: row-reverse;
	}

	&-main {
		flex: 1;
	}

	&-side {
		position: relative;
		min-width: 260px;

		@include screen('sm') {
			margin-left: 2rem;
		}

		&-background {
			@include background-right;
			left: 0;
			top: auto;
			bottom: auto;
			background-image: url('~src/assets/images/bricks/body-wide-desktop-right.svg');
			background-repeat: no-repeat;
			min-height: 601px;
			width: 260px;
			margin-left: -250px;
			margin-top: 45px;
			display: none;

			@include screen('sm') {
				display: block;
			}
		}
	}
}
