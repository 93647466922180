@import '~src/theme/_library.scss';

/*
	This skip sibling is used to ensure that pressing tab
	after a fresh route change will display the Skip to content
	button without fail.
*/
.skip-button-previous-sibling {
	position: absolute;
	display: inline;
	left: -9999px;
}

.skip-to-content {
	position: absolute;
	padding: 10px 15px;
	left: -9999px;
	height: 1px;
	width: 1px;
	color: color('white');
	background: color('blue');
	font-size: 1rem;
	border: 0;
	border-radius: 5px;
	user-select: none;
	z-index: 100;

	&:active,
	&:focus,
	&:hover {
		display: inline-block;
		top: 0;
		left: 0;
		width: auto;
		height: auto;
		outline: none;
		overflow: visible;
		cursor: pointer;
	}

	&:hover {
		color: color('white');
		background: color('blue-light');
	}
}
