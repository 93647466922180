$screens: (
	'sm': 768px,
	'md': 992px,
	'lg': 1200px,
);

@mixin screen($screen) {
	@if map-has-key($screens, $screen) == false {
		@error Invalid screen $screen;
	}

	@media (min-width: map-get($screens, $screen)) {
		@content;
	}
}

/*
 * TODO Potentially replace areas of the app to use the standards based media queries
 * Added this so that no existing use of screen() would break.
 * The app needed updated xs, sm, md, lg and xl values to match the standards
 */
$screenSizes: (
	'xs': 320px,
	'sm': 576px,
	'md': 768px,
	'lg': 992px,
	'xl': 1200px,
);

@mixin media-breakpoint($screenSize) {
	@if map-has-key($screenSizes, $screenSize) == false {
		@error Incorrect screen parameter passed to mixin, $screenSize;
	}

	@media only screen and (min-width: map-get($screenSizes, $screenSize)) {
		@content;
	}
}
