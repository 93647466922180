@import '~src/theme/_library.scss';
@import './PageHeaderBackground';

.page-header {
	&-base {
		display: flex;
		width: 100%;
	}

	&-background {
		@include PageHeaderBackground;
	}

	&-text {
		color: white;

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			color: white;
		}
	}
}
