@import './_color.scss';
@import './_font.scss';
@import './_screen.scss';
@import './_weight.scss';

@mixin no-appearance-button {
	appearance: none;
	background: transparent;
	border: none;
	color: inherit;
	font-weight: inherit;
	letter-spacing: inherit;
	margin: 0;
	outline: none;
	padding: 0;
	text-decoration: none;
}

@mixin page-padding {
	padding-left: 2rem;
	padding-right: 2rem;

	@include screen('sm') {
		padding-left: 3rem;
		padding-right: 3rem;
	}
	@include screen('md') {
		padding-left: 5rem;
		padding-right: 5rem;
	}
	@include screen('lg') {
		padding-left: 6rem;
		padding-right: 6rem;
	}
}

@mixin page-width {
	box-sizing: border-box;
	max-width: 100%;
	margin-left: auto;
	margin-right: auto;
	width: 684px;

	@include screen('sm') {
		width: 852px;
	}
	@include screen('md') {
		width: 1032px;
	}
	@include screen('lg') {
		width: 1400px;
	}
}
