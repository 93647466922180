@import '~src/theme/_library.scss';

.modal {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 9999;

	&-scrollable {
		overflow-y: auto;
		height: 100%;
		position: relative;
		z-index: 1;
	}

	&-center {
		position: relative;
		justify-content: center;
		align-items: center;
		display: flex;
		min-height: 100%;
	}
}

.modal-overlay {
	opacity: 1;
	position: absolute;
	background: rgba($color: #000000, $alpha: 0.75);
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;

	&-interactive {
		cursor: pointer;
	}
}

.modal-content {
	width: 450px;
	max-width: 100%;
	margin: 3rem;
	background: color('white');
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.13);
	border-radius: 2.5px;
	position: relative;
	z-index: 1;
}

.modal-close-btn {
	@include no-appearance-button();
	position: absolute;
	top: 0;
	right: 0;
	transform: translate(50%, -50%);
	background-color: color('gray-light');
	color: color('red');
	border-radius: 50%;
	cursor: pointer;
	transition: 150ms;

	&:hover {
		background-color: color('gray-lightest');
	}

	svg {
		width: 1rem !important;
		height: 1rem !important;
		padding: 0.35rem;
		vertical-align: top;
	}
	// font-size: 20px;
}

.modal-child-content {
	padding: 2rem;

	.sub-header {
		color: #00a2db;
		font-size: 1.25rem;
		font-family: Roboto, sans-serif;
		margin: 5px 0;
	}
}
