@import '~src/theme/_library.scss';

.errors {
	padding: 1rem;

	ul {
		margin: 0;
		margin-top: 0.5rem;
	}
}
