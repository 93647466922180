@import '~src/theme/_library.scss';

// Document each style
$font-size: 16px;
$spacing: 1.5rem;
$text-Alignment: left;

.confirmation {
	&-title {
		font-size: 1.25rem;
		margin: 0;
		font-weight: weight('medium');
		color: color('black');
		font-family: font('body');
		text-transform: none;
		text-align: $text-Alignment;
		line-height: 1;
		letter-spacing: 0;
		margin-bottom: $spacing;
	}

	&-message {
		color: #333;
		margin-top: $spacing;
		margin-bottom: $spacing;
		line-height: 1.79;
		text-align: $text-Alignment;
	}

	&-message-block {
		p {
			margin: 0.5em 0;
		}
	}

	&-cta {
		display: flex;
		align-items: flex-start;
		justify-content: flex-end;
		flex-direction: column;
		margin-top: $spacing;

		@include screen('sm') {
			flex-direction: row;
		}

		&-confirm,
		&-cancel {
			@include no-appearance-button();
			font-weight: weight('medium');
			cursor: pointer;
			margin: 0.75rem 0;
			transition: 150ms;

			@include screen('sm') {
				margin: 0 $spacing;
			}
		}

		&-confirm {
			color: color('blue-light');
			margin-bottom: 0;
			margin-right: 0;
			&:hover {
				color: color('blue');
			}
		}

		&-cancel {
			color: color('gray');
			&:hover {
				color: color('gray-dark');
			}
		}
	}
}
