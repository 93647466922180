@import '~src/theme/_library.scss';

.error-page {
	box-sizing: border-box;
	padding: 3rem 0;
	min-height: 100%;
	display: flex;
	flex: 1 1 auto;
	align-items: center;
}

.wrapper {
	text-align: center;
}

.title {
	font-size: 100px;
	margin: 4rem 0 3rem;
	letter-spacing: 3.92px;
	color: color('blue');
}

.image-wrapper {
	margin-bottom: 3rem;
}

.subtitle {
	font-size: 30px;
	letter-spacing: 1.25px;
	margin: 1.5rem 0;
	color: color('blue-light');
}

.button {
	width: 100%;
	margin: 2rem 0;
	@include screen('sm') {
		width: auto;
	}
}

.centered {
	width: 100%;
	text-align: center;
	margin-bottom: 2rem;

	& > div {
		width: 25rem;
		max-width: 100%;
		display: inline-block;
	}
}

.action-buttons {
	button {
		&:first-of-type {
			margin-right: 1rem;
			margin-bottom: 1rem;
		}
	}
}
